import React, {useEffect, useState} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import GraphImg2 from "../../assests/images/graph2.png";
import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Preval_Presentation"; 
import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import VideoModule from "../../components/modules/video-module"

const PresentationPage = () => {

    const [ playVideo, setPlayVideo ] = useState(false);
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('marketing-analytics');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    //console.log("article_data", article_data);

    return <>
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
    <div className="pre-wrapper">

     <Header />

      <div className="section-wrapper">
          <Container>
            <h2>{article_data && article_data.Title}

                <div className={"title_nav"}>
                    <Link to={config.preval_marketing} className="left-icon" title="Previous">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1785 0.678711L14.9463 2.44648L7.70517 9.6876L14.9463 16.9287L13.1785 18.6965L4.16964 9.6876L13.1785 0.678711Z" fill="#67707B"/>
                        </svg>
                    </Link>

                    <Link to={config.preval_360_tours} className="right-icon" title="Next">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82148 18.6963L5.05371 16.9285L12.2948 9.68741L5.05371 2.44629L6.82148 0.678522L15.8304 9.68741L6.82148 18.6963Z" fill="#67707B"/>
                        </svg>
                    </Link>
                </div>
            </h2>
            <div className="example-wrapper">
                <div className="graph-sec">
                  {article_data && HTMLReactParser(article_data.Content)}

                  <div class="section-wrap">
                    <div class="left-aside">
                        <div className="property-wrap">
                            {
                                article_data && article_data.Modules && article_data.Modules[0].Stats_Counter_Block.map((stats, index) => {
                                    return(
                                        <div class="property-card">
                                            <div class="property-block">
                                                <strong>{stats.Title}</strong>
                                                <p>{stats.Content}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {/* property-card */}
                        </div>
                    {/* property-wrap */}
                    </div>
                    {/* left-aside */}
                    <div class="right-aside">
                        <figure>
                            <img src={GraphImg2} alt="graph" />
                        </figure>
                    </div>
                    {/* right-aside */}
                  </div>
                  {/* section-wrap */}
                </div>
                {/* graph-wrap */}
            
            </div>
            {/* property-wrapper */}
          </Container>
      </div>
      {/* section-wrapper */}

      
      
      <NavLink tab_name="Marketing" prevLink={config.preval_people}  nextLink={config.preval_valuation_day} />
    </div>
    </>
}

export default PresentationPage